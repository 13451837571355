import React from "react";
import LpDemo from "../../../page-components/LpDemo";

export default function DemoPageSideKick() {
  return (
    <LpDemo
      leadType="email"
      formSubHeader="Adapt and grow your restaurant with an end-to-end solution built by restaurateurs."
      formId="b0ed9c15-8558-4a9d-9206-dffdae2b83a1"
      useShortForm
      withLineOfBusinessOption
      toggleVisibilityMap={[
        {
          changeOnKey: "industry",
          changeOnValue: "Other",
          togglesItem: "business_type",
        },
      ]}
    />
  );
}
